.WhyUs {
    margin: 40px 0px;
}
.SectionTitle {
    font-size: 40px;
    color: gray;
    margin-bottom: 20px;
}
.WhyUsContent {
    background-color: red;
}
.WhyUsDescription {
    text-align: left;
    display: inline-block;
    width: 350px;
    background-color: #f0f4f7;
    border-radius: 5px;
    vertical-align: top;
    margin: 0px 20px 20px 0px;
    min-height: 275px;
}
.DescriptionTitle {
    color: rgb(255, 0, 0);
    text-align: center;
    padding-top: 20px;
    margin-bottom: 10px;
    border-bottom: rgba(255, 0, 0, 0.2) 1px solid;
    background-color: rgba(196, 196, 196, 0.2);
    height: 40px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;    
}
.DescriptionContent {
    padding: 10px 10px 20px 10px;
}

/* smaller devices */
@media (max-width: 767px) {
    .WhyUsDescription {
        text-align: left;
        display: block;
        background-color: #f0f4f7;
        border-radius: 5px;
        margin: 0px 20px 20px 0px;
        min-height: auto;
        width: 100%;
        padding-bottom: 10px;
    }
}