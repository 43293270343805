.Footer {
    padding: 0px 0px 60px 0px;
    background-color: #2a2d30;
    color: #fff;
}
.FooterLogoContent{
    float: left;
}
.FooterLogo {
    display: inline-block;
}
.FooterLogo>img {
    height: 30px;
    width: auto;
    background-color: #f5f5f5;
    border-radius: 50%;
    margin-top: 10px;
    padding: 2px;
}
.FooterOrgName {
    display: inline-block;
    font-size: 20px;
    margin-left: 10px;
}
.CopyRight {
    float: right;
    margin-top: 10px;
    font-size: 11px;
}

/* smaller devices */
@media (max-width: 767px) {
    .Footer {
        padding: 0px 0px 20px 0px;
    }
    .FooterLogoContent{
        float: none;
    }
    .CopyRight {
        float: none;
        font-size: 11px;
    }
}