.header {
    background-image: url("../../assets/cityview.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.headerContent {
    align-items: center;
    bottom: 0;
    position: absolute;
    min-height: 250px;
    text-align: center;
    width: 100%;
    font-size: 20px;
    /* margin-bottom: 20px; */
    padding-top: 50px;
}
.Link>a, a:hover, a:focus, a:active {
    text-decoration: none;
    color: #000;
}

/* smaller devices */
@media (max-width: 767px) {
    .header {
        height: 400px;
    }
    .headerContent {
        margin-top: 250px;
        bottom: auto;
        text-align: center;
        min-height: 130px;
        padding-top: 20px;
        background-color: rgba(255, 255, 255, 0.5);
    }

    /*----Menu----*/
    .Menu {
        height: 70px;
        border-bottom: solid 0px rgb(255, 255, 255, 0.5);    
    }
    .LogoContent {
        float: left;
    }
    .Logo {
        height: 50px;
        width: auto;
    }
    .OrgName {
        font-size: 30px;
        margin-left: 2px;
    }
    .MenuBar>img {
        height: 20px;
        width: auto;
    }
    .viewMenuIcon {
        display: block;
    }
    .hideMenuIcon {
        display: none;
    }
    .VerticalMenu {
        background-color: rgba(255, 255, 255, 0.7);
        width: 100%;
        margin-top: 65px;
        line-height: 29px;
        font-style: italic;
    }

    /*----MenuLink----*/
    .MenuLinks {
        display: none;
    }
    .MenuBar {
        display: block;
        float: right;
        margin-top: 20px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 1199px) {
    .header {
        height: 400px;
    }
    .headerContent {
        margin-top: 250px;
        bottom: auto;
        text-align: center;
        min-height: 130px;
        padding-top: 20px;
        background-color: rgba(255, 255, 255, 0.5);
    }

    /*----Menu----*/
    .Menu {
        height: 70px;
        border-bottom: solid 0px rgb(255, 255, 255, 0.5);    
    }
    .LogoContent {
        float: left;
    }
    .Logo {
        height: 50px;
        width: auto;
    }
    .OrgName {
        font-size: 30px;
        margin-left: 2px;
    }
    .MenuBar>img {
        height: 20px;
        width: auto;
    }
    .viewMenuIcon {
        display: block;
    }
    .hideMenuIcon {
        display: none;
    }
    .VerticalMenu {
        background-color: rgba(255, 255, 255, 0.5);
        width: 100%;
        margin-top: 65px;
        line-height: 29px;
        font-style: italic;
    }

    /*----MenuLink----*/
    .MenuLinks {
        display: none;
    }
    .MenuBar {
        display: block;
        float: right;
        margin-top: 20px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media (min-width: 1200px) {
    .header {
        height: 700px;
    }
    .headerContent {        
        padding-top: 0px;
        bottom: 22px;
        background: -moz-linear-gradient(top,  rgba(175,175,175,0) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  rgba(175,175,175,0) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  rgba(175,175,175,0) 0%,rgba(255,255,255,0.8) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00afafaf', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
    }

    /*----Menu----*/
    .Menu {
        height: 90px;
        /* border-bottom: solid 1px rgb(255, 255, 255, 0.5);     */
    }
    .LogoContent {
        float: left;
    }
    .Logo {
        height: 50px;
        width: auto;
    }
    .OrgName {
        font-size: 40px;
        margin-left: 10px;
    }
    .Slogan {
        font-size: 50px;
        color: #fff;
        box-shadow: #fff;
        text-shadow: 1px 1px 1px #000;
    }
    .Slogan>span {
        font-size: 80px;
        font-weight: bolder;
        text-shadow: 1px 1px 1px red;
    }
    .SloganReason {
        font-size: 30px;
        color: #000;
        font-style: italic;
    }

    /*----MenuLink----*/
    .MenuLinks {
        float: right;
        margin-top: 20px;
    }
    .MenuBar {
        display: none;
    }
    .Link {
        display: inline-block;
        margin-right: 30px;
        cursor: pointer;
        font-weight: bold;
        font-variant: small-caps;
    }    
    .Link:hover {
        border-bottom: solid 1px rgba(255, 0, 0, 0.99);
    }
}